<template>
  <!-- Support Balloon -->

  <div class="chat-area">
    <div class="support-box shadow">
      <Header />
      <Body />
    </div>
    <Button />
  </div>

  <!-- ./Support Balloon -->
</template>

<script>
import Header from "./components/Header/Index";
import Body from "./components/Body/Index";
import Button from "./components/Button/Index";

export default {
  name: "WeAppSupport",
  components: {
    Header,
    Body,
    Button,
  },
};
</script>

<style lang="scss">
.support-button {
  color: #ffffff;
  text-decoration: none;
  background-color: #1e90ff;
  border-radius: 100%;
  transition: 0.4s all;
  width: 50px;
  height: 50px;
  line-height: 53px;
  position: fixed;
  right: 20px;
  bottom: 80px;
  text-align: center;
  z-index: 1030;
}

.support-button.is-open:hover {
  background-color: #ff4757;
}

.support-button:hover {
  color: #ffffff;
  background-color: #2ed573;
}

.support-box {
  position: fixed;
  height: 0;
  width: 0;
  background-color: #1e90ff;
  padding: 3px;
  border-radius: 6px;
  transition: 0.4s all;
  display: none;
  z-index: 1030;
}

.support-box.opened {
  height: 600px;
  width: 350px;
  right: 20px;
  bottom: 80px;
  visibility: visible;
  display: block;
  opacity: 1;
  z-index: 1030;
}

.chat-header {
  background-color: #1e90ff;
}

.agent-image {
  background-color: #21d15c62;
}

.chat-body {
  background-color: #ffffff;
  height: 87%;
  border-radius: 6px;
  font-size: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  min-height: 461px;
  max-height: 520px;
  overflow: auto;
  padding: 10px 0;
}

.timestamp {
  font-size: 13px;
  color: #7a7a7a;
}

.msg-content {
  margin-bottom: 0.5rem;
}

.msg-content p {
  margin: 0;
}

.chat--receipent .msg-content {
  background-color: #ced6e04b;
  border-radius: 6px;
  padding: 1rem;
}

.chat--sender .msg-content {
  background-color: #1e8fffa9;
  border-radius: 6px;
  padding: 1rem;
}

.chat--receipent .timestamp {
  float: right;
}

.chat--sender .timestamp {
  float: left;
}

.msg-send-area {
  width: 100%;
  bottom: 0;
  border-top: 1px solid rgba(128, 128, 128, 0.295);
  background-color: rgb(197, 197, 197);
  padding: 0.5rem;
}

@media all and (max-width: 768px) {
  .support-box.opened {
    position: fixed;
    width: auto;
    margin: 5px;
  }
}
</style>
