<template>
  <span class="support-button">
    <i class="fas fa-comment fa-lg"></i>
  </span>
</template>

<script>
export default {
  name: "Button",
};
</script>

<style lang="scss" scoped>
.support-button {
  cursor: pointer;
}
</style>
