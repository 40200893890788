<template>
  <!-- Chat Box Header -->
  <div class="media chat-header p-2 align-items-center">
    <div class="col-auto rounded-circle p-2 agent-image">
      <img src="https://picsum.photos/45/45" class="rounded-circle" alt />
    </div>
    <div class="media-body ml-3">
      <span class="h6 text-white">Support Name</span>
      <span class="d-block">
        <small>
          <i>Çevrimiçi</i>
        </small>
      </span>
    </div>
    <button type="button" class="close close-chat mr-2 font-15">
      <i class="fas fa-times fa-lg"></i>
    </button>
  </div>
  <!-- ./Chat Box Header -->
</template>

<script>
export default {
  name: "Header",
};
</script>