<template>
  <!-- Received Message -->
  <div class="col chat--receipent mb-4">
    <div class="media">
      <!-- Person Image -->
      <img src="https://picsum.photos/45/45" class="rounded-circle" alt />
      <!-- ./Person Image -->

      <!-- Chat Message -->
      <div class="media-body ml-2">
        <!-- <span class="text-custom-primary font-weight-bold">Support Name</span> -->
        <div class="msg-content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            posuere dictum neque placerat condimentum. Vivamus lobortis nec mi
            vitae placerat. Sed eget odio laoreet, mattis leo in, convallis sem.
          </p>
        </div>
        <div class="msg-content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            posuere dictum neque placerat condimentum. Vivamus lobortis nec mi
            vitae placerat. Sed eget odio laoreet, mattis leo in, convallis sem.
          </p>
        </div>
        <div class="msg-content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            posuere dictum neque placerat condimentum. Vivamus lobortis nec mi
            vitae placerat. Sed eget odio laoreet, mattis leo in, convallis sem.
          </p>
        </div>
        <span class="timestamp">19:38</span>
      </div>
      <!-- ./Chat Message -->
    </div>
  </div>
  <!-- ./Received Message -->
</template>

<script>
export default {
  name: "Received",
};
</script>
