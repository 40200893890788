<template>
  <!-- Chat Body -->
  <div class="chat-body">
    <div class="chat-messages custom-scrollbar">
      <Received />
      <Sender />
    </div>
    <Message />
  </div>
  <!-- ./Chat Body -->
</template>

<script>
import Received from "./components/Received/Index";
import Sender from "./components/Sender/Index";
import Message from "./components/Message/Index";

export default {
  name: "Body",
  components: {
    Received,
    Sender,
    Message,
  },
};
</script>
