<template>
  <div class="d-block msg-send-area">
    <div class="col-12">
      <div class="row">
        <div class="col pl-0">
          <input type="text" class="form-control" placeholder="Mesaj yazın" />
        </div>
        <div class="col-auto p-0">
          <button type="submit" class="btn btn-primary">
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
};
</script>
