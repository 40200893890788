<template>
  <!-- Sender Message -->
  <div class="col chat--sender mb-4">
    <div class="media">
      <!-- Chat Message -->
      <div class="media-body mr-2">
        <!-- Group Messages -->
        <div class="msg-content">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <div class="msg-content">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <!-- ../Group Messages -->

        <span class="timestamp">19:38</span>
      </div>
      <!-- ./Chat Message -->

      <!-- Person Image -->
      <img src="https://picsum.photos/45/45" class="rounded-circle" alt />
      <!-- ./Person Image -->
    </div>
  </div>
  <!-- ./Sender Message -->
</template>

<script>
export default {
  name: "Sender",
};
</script>
